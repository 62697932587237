import React from 'react';
import styled from 'styled-components';
import { useSwiper } from 'swiper/react';
import Arrow from 'assets/icons/arrow-left.svg';

const SwiperButtons = () => {
  const swiper = useSwiper();

  return (
    <ControlsContainer>
      <StyledButtonPrev onClick={() => swiper.slidePrev()}>
        <SvgArrowLeft />
      </StyledButtonPrev>
      <StyledButtonNext onClick={() => swiper.slideNext()}>
        <SvgArrowRight />
      </StyledButtonNext>
    </ControlsContainer>
  );
};

export default SwiperButtons;

const SvgArrowRight = styled(Arrow)`
  svg {
    height: 24px;
    width: 24px;
  }
  & path {
    fill: #fff;
  }
`;

const SvgArrowLeft = styled(Arrow)`
  svg {
    height: 24px;
    width: 24px;
  }
  & path {
    fill: #fff;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
`;

const StyledButtonPrev = styled.div`
  align-items: center;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 5px;
  transition: all 0.3s ease;
  width: 48px;
  fill: #fff;
  svg {
    height: 24px;
    width: 24px;
  }
`;

const StyledButtonNext = styled.div`
  align-items: center;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 5px;
  transition: all 0.3s ease;
  width: 48px;
  fill: #fff;
  margin-left: 32px;
  transform: rotate(180deg);
  svg {
    height: 24px;
    width: 24px;
  }
`;
